<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
    <!-- <Home msg="Welcome to Your Vue.js App" /> -->
    <router-view />
    <div class="container-fluid">
      <div class="row">
        <footer class="col-12" id="footer">
          <p id="contact">
            <span class="upper"> Telephone </span>
            <a href="tel:+41445526590">+41 44 552 65 90</a>
            <br />
            <span class="upper">Email </span>
            <a href="mailto:info@bureauluethi.com">info@bureauluethi.com</a>
            <br />
          </p>
          <p id="adress">
            Bureau Lüthi AG <br />St. Jakobstrasse 31 <br />8004 Zürich<br />Switzerland
          </p>
          <p id="adress-palma">
            Carrer de Monsenyor Palmer, 1<br />
            07014 Palma<br />
            Spain
          </p>
          <div class="icons d-lg-none">
            <a href="https://instagram.com/bureauluethi" target="_blank" rel="noopener noreferrer"
              ><img :src="require('@/assets/images/instagram.svg')" alt="Instagram Icon" />
            </a>
            <a
              href="https://www.linkedin.com/company/bureau-lüthi-architecture-zürich/"
              target="_blank"
              rel="noopener noreferrer">
              <img :src="require('@/assets/images/linkedin.svg')" alt="Linkedin Icon" />
            </a>
          </div>
          <p id="social">
            <a href="https://instagram.com/bureauluethi" target="_blank" rel="noopener noreferrer"
              >Instagram</a
            >
            <a
              href="https://www.linkedin.com/company/bureau-lüthi-architecture-zürich/"
              target="_blank"
              rel="noopener noreferrer"
              >LinkedIn</a
            ><br />
            © {{ currentYear }} Bureau Lüthi AG
            <br />
            <router-link to="/impressum">
              <span class="">Impressum</span>
            </router-link>
          </p>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss">
@import "node_modules/bootstrap-scss/bootstrap-grid.scss";
*,
body {
  margin: 0;
  padding: 0;
}

html {
  font-size: 8px;
}

.upper {
  text-transform: uppercase;
}

#app {
  font-family: atten-new, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

a {
  text-decoration: none;
  color: black;
}

#footer {
  display: flex;
  flex-direction: column;
  row-gap: 5rem;
  column-gap: 8rem;

  width: 100%;
  padding: 0 5%;
  margin-top: 20rem;
  margin-bottom: 5rem;

  p {
    font-size: 2.5rem;
    line-height: 140%;
    // margin-bottom: 5rem;
    text-align: center;

    &#social {
      // margin-left: auto;
      // text-align: right;

      a {
        &:nth-child(1) {
          margin-right: 2rem;
        }
      }
    }
  }

  .icons {
    display: flex;
    justify-content: center;

    img {
      width: 40px;
      margin: 0 16px 0px 16px;
    }
  }

  @media (min-width: 992px) {
    flex-direction: row;

    p {
      &#contact {
        text-align: left;
      }
      &#adress {
        text-align: left;
      }
      &#adress-palma {
        text-align: left;
      }

      &#social {
        margin-left: auto;
        text-align: right;
      }
    }
  }
}
</style>
